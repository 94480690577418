import type { GetServicesApp } from '~/helpers/parsers/ServicesDTO';
import type { ServerResponseCommonType } from '~/types/commons';
import { useNuxtApp } from '#imports';
import type { FetchError } from 'ofetch';
import type { Ref } from 'vue';

type ServicesStoreType = {
  serviceList: Ref<GetServicesApp[] | null>;
  setServices: (services: GetServicesApp[]) => void;
  getServices: () => Promise<GetServicesApp[] | null>;
}
export const servicesStore = defineStore('services-store', (): ServicesStoreType => {
  const { $api } = useNuxtApp();
  const serviceList = ref<GetServicesApp[] | null>(null);

  function setServices (services: GetServicesApp[]) {
    serviceList.value = services;
  }

  async function getServices (): Promise<GetServicesApp[] | null> {
    const res = await $api<ServerResponseCommonType<GetServicesApp[]>>('/activation/getServices').catch((err: FetchError) => {
      console.log('error during getting services:', err.data);
      return null;
    });

    if (res?.data) {
      setServices(res.data);
      return res.data;
    }

    return null;
  }

  return {
    serviceList,
    setServices,
    getServices
  };
});
